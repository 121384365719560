<script>
  import RedirectButton from './RedirectButton.svelte';

  // configs
  export let origin;
  export let destination;
  export let funnel = '';

  // design
  export let image = '';
  export let title = '';
  export let description = '';
  export let mainColor = '';
  export let price = '0';
  export let currency = 'MXN';

  // variants
  export let prominent = false;
  export let compact = false;

  $: variant =
    prominent ? 'prominent' :
    compact ? 'compact' :
    '';
</script>

<style>
  .route {
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: -2px 0 10px rgba(0,0,0,0.3);
    max-width: 285px;
    overflow: hidden;
    width: 100%;
  }

  .route *,
  .route *:after,
  .route *:before {
    box-sizing: inherit;
  }

  .route-top {
    background-position: center;
    background-size: cover;

    height: 250px;
    position: relative;
  }
  .route-overlay {
    align-items: flex-end;
    background: linear-gradient(0deg, rgba(2,0,36,0.80) 0%, rgba(255,255,255,0) 50%);
    display: flex;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 10px;
    position: absolute;
  }
  .route-title {
    color: white;
    font: 400 16px "Open Sans", sans-serif;
    margin: 0;
    font-weight: 600;
  }
  .route-bottom {
    background-color: #FAFAFA;
    padding: 15px 10px;
  }
  .route-info {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .route-description {
    color: #666666;
    font: 400 12px "Open Sans", sans-serif;
    margin: 0;
  }
  .route-price {
    margin: 0;
    color: #232323;
    font: 700 18px "Open Sans", sans-serif;
  }
  .route-price span {
    font-size: 12px;
  }

  /* prominent variant */
  .prominent {
    max-width: 385px;
    background-color: red;
  }

  /* compact variant */
  .compact .route-overlay {
    background: linear-gradient(0deg, rgba(2,0,36,0.80) 0%, rgb(0 0 0 / 19%) 50%);
    flex-direction:column;
    justify-content: space-between;
    align-items: stretch;
  }

  .compact .route-bottom{
    display: none;
  }

  .compact .route-title {
    font-size: 22px;
  }

  .compact .route-description {
    color: #ffffff;
    font-size: 16px;
  }

  .compact .route-price {
    color: #ffffff;
    font-size: 22px;
  }

  .compact .compact-info {
    display: flex;
    justify-content: space-between;
  }
  
  .show-compact {
    display: none;
  }

  .compact .show-compact {
    display: flex;
  }

  .compact.route {
    border-radius: 0;
    box-shadow: none;
  }

  @media only screen and (min-width: 770px) and (max-width: 1040px) {
    .route-price{
      font-size: 15px;
    }
  }

  @media only screen and (max-width: 769px){
    .route {
      max-width: 100%;
    }
  }
</style>

<div class="route {variant}">
  <div class="route-top" style={`background-image: url("${image}")`}>
    <div class="route-overlay">
    <div>
      <p class="route-title">{title}</p>
      <p class="route-description show-compact">{description}</p>
    </div>
      <div class="compact-info show-compact">
        <p class="route-price">
          ${price}
          <span>{currency}</span>
        </p>
        <RedirectButton
          funnel={funnel}
          origin={origin}
          destination={destination}
          color={mainColor}
        >
          Ver horarios
        </RedirectButton>
      </div>
    </div>
  </div>
  <div class="route-bottom">
    <p class="route-description">{description}</p>
    <div class="route-info">
      <p class="route-price">
        ${price}
        <span>{currency}</span>
      </p>
      <RedirectButton 
        funnel={funnel}
        origin={origin}
        destination={destination}
        color={mainColor}
      >
        Ver horarios
      </RedirectButton>
    </div>
  </div>
</div>
