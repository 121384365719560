<script>
  import getToday from '../utils/getToday';

  // config props
  export let funnel;
  export let origin;
  export let destination;

  // style props
  export let color = '';

  $: href = `${funnel}/search/${origin}/${destination}/${getToday()}/p/A1/providers`;
</script>

<style>
  .route-action {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    box-sizing: inherit;
    font: 600 12px "Open Sans", sans-serif;
    padding: 5px 10px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    transition: all .2s ease-in-out;
  }
  .route-action:hover {
    background-color: #00b300;
    color: white !important;
  }

  .route-action:focus {
    box-shadow: 0 0 10px 2px #00000090;
    outline: dashed 2px #00000030;
  }

  @media only screen and (min-width: 770px) and (max-width: 1040px) {
    .route-action{
      padding: 0 5px;
    }
  }
</style>

<a class="route-action" href={href}
  style={`
    border-color: #${color};
    color: #${color};
  `}
>
  <slot />
</a>
