import Route from './components/Route.svelte';
import camelizeAttribute from './utils/camelizeAttribute';
import './initializers';

class RouteComponent extends HTMLElement {
  constructor() {
    super();
    this.component = new Route({
      target: this,
      props: this.getAttributes(),
    });
  }

  getAttributes() {
    const attributes = Array.prototype.reduce.call(this.attributes, (accumulator, attr) => ({
      ...accumulator,
      [camelizeAttribute(attr.name)]: attr.value === '' ? true : attr.value,
    }), {});
    return attributes;
  }
}

customElements.define('route-component', RouteComponent);
